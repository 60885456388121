import { ref } from 'vue'
import { TOKEN_KEY } from '@/main'
import axios from 'axios'

const user = ref(null)

const useUser = () => {
  const hasUser = () => !!user.value

  const hasKeys = () => !!localStorage.getItem(TOKEN_KEY)

  const isAuthenticated = () => hasUser() && hasKeys()

  const checkAuthenticated = async () => {
    if (isAuthenticated()) return true
    if (!hasKeys()) return false
    try {
      const { data } = await axios.get('/user')
      user.value = data
      return true
    } catch (e) {
      return false
    }
  }

  const logout = async () => {
    try {
      await axios.post('/logout')
      localStorage.removeItem(TOKEN_KEY)
      window.location.href = '/'
    } catch (e) {
      console.log(e)
    }
  }

  return {
    user,
    checkAuthenticated,
    logout
  }
}

export {
  useUser
}
