import { createRouter, createWebHistory } from '@ionic/vue-router';
import AuthContainer from '@/components/AuthContainer.vue'

import { useUser } from '@/use/user'

const routes = [
  {
    path: '/',
    redirect: '/periods'
  },
  {
    path: '/',
    component: AuthContainer,
    meta: { auth: true },
    children: [
      {
        path: 'periods',
        component: () => import('@/views/Periods.vue')
      },
      {
        path: 'notifies',
        component: () => import('@/views/Notifies.vue')
      },
      {
        path: 'profile',
        component: () => import('@/views/Profile.vue')
      },
      {
        path: 'report/:code',
        props: true,
        component: () => import('@/views/Report.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/restore',
    name: 'Restore',
    component: () => import('@/views/Restore.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: '/periods'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const { checkAuthenticated } = useUser()

router.beforeEach(async (to, from, next) => {
  if (to.meta && to.meta.auth) {
    if (await checkAuthenticated()) next()
    else next('/login')
  } else if (to.name === 'Login') {
    if (await checkAuthenticated()) next('/')
    else next()
  } else {
    next()
  }
})

export default router
