<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="periods" href="/periods">
          <ion-icon :icon="list" />
          <ion-label>Мои периоды</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="notifies" href="/notifies">
          <ion-icon :icon="notifications" />
          <ion-label>Уведомления</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="profile" href="/profile">
          <ion-icon :icon="person" />
          <ion-label>Профиль</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { list, notifications, person } from 'ionicons/icons';

export default {
  name: 'AuthContainer',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {
    return {
      list,
      notifications,
      person
    }
  }
}
</script>
